import { type PropertyInput } from "@interfaces";
import {
    CheckboxInput,
    LabeledNumberInput,
    LabeledSelectInput,
    LabeledTextInput,
    LabelledDateSelectInput,
} from "components";
import { useParams } from "react-router-dom";
import { usePropertyCreation } from "services/property-creation-context";
import { usePropertyEdit } from "services/property-edit-context";

export const DisplayInput = (inputProperties: PropertyInput): JSX.Element => {
    const { id } = useParams();
    const {
        property,
        handleTextChange,
        handleCheckboxChange,
        handleDateChange,
        handleSelectChange,
        handleNumberChange,
        handleRadioButtonChange,
        handleTextAreaChange,
        validationErrors,
    } = id ? usePropertyEdit() : usePropertyCreation();

    const { name, label, enumObject, type, automatedValue, fullWidth } =
        inputProperties;

    const hasError = validationErrors.includes(inputProperties.name);

    if (type === "text") {
        return (
            <LabeledTextInput
                {...inputProperties}
                value={property[name] as string}
                handleChange={handleTextChange}
                hasError={hasError}
                fullWidth
            />
        );
    }
    if (type === "number") {
        // const value =
        //     typeof property[name] === "string"
        //         ? Number(property[name])
        //         : property[name];
        return (
            <LabeledNumberInput
                {...inputProperties}
                value={property[name] as string}
                handleChange={handleNumberChange}
                hasError={hasError}
                fullWidth
            />
        );
    }
    if (type === "select" && enumObject) {
        return (
            <>
                <LabeledSelectInput
                    name={name}
                    label={label}
                    enumObject={enumObject}
                    value={property[name] as string}
                    handleSelectChange={handleSelectChange}
                    hasError={hasError}
                    fullWidth
                    disabled={inputProperties.disabled}
                />
            </>
        );
    }
    if (type === "date") {
        return (
            <LabelledDateSelectInput
                {...inputProperties}
                value={property[name] as string}
                handleChange={handleDateChange}
                hasError={hasError}
                fullWidth
            />
        );
    }
    if (type === "checkbox") {
        return (
            <CheckboxInput
                {...inputProperties}
                checked={property[name] as boolean}
                hasError={hasError}
                handleChange={handleCheckboxChange}
            />
        );
    }
    if (type === "auto_calculated" && automatedValue) {
        return (
            <LabeledNumberInput
                {...inputProperties}
                handleChange={handleNumberChange}
                fullWidth={fullWidth}
                value={automatedValue.toString()}
                hasError={hasError}
            />
        );
    }

    return <></>;
};
