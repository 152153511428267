import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";
import { LabeledSelectInput } from "components/shared/form/SelectInput";
import { usePropertyLabels } from "hooks";
import {
    EnergyCardTypeOptions,
    EnergyDateOfCreationOptions,
    EnergyPassportOptions,
} from "enums";
import { TwoColumnForm } from "components/shared/form/TwoColumnForm";
import { LabeledNumberInput } from "components/shared/form/TextInput";
import { type Property } from "interfaces";
import { CheckboxInput } from "components/shared/form/CheckboxInput";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";
import { InputValidationError } from "components/properties/ValidationError";

const HEATING_OPTIONS = [
    "central_heating",
    "underfloor_heating",
    "storey_heating",
    "tiled_stove",
    "open_fireplace",
];
const ESSENTIAL_ENERGY_SOURCES = [
    "gas",
    "district_heating",
    "oil",
    "electric",
    "pellets",
    "solar",
];

export const StepFourForm = () => {
    const { id } = useParams();
    const labels = usePropertyLabels();

    const {
        property,
        handleNumberChange,
        handleCheckboxChange,
        handleSelectChange,
        validationErrors,
    } = id ? usePropertyEdit() : usePropertyCreation();

    return property.property_type !== "plot" ? (
        <Box sx={propertyFormStyles.centeredContainer}>
            <TwoColumnForm
                leftSide={
                    <Box>
                        <LabeledSelectInput
                            label={labels.energy_certificate + "*"}
                            enumObject={EnergyPassportOptions}
                            name="energy_certificate"
                            handleSelectChange={handleSelectChange}
                            value={property.energy_certificate}
                            fullWidth
                            mb={"2rem"}
                            disabled={id !== undefined}
                            hasError={validationErrors.includes(
                                "energy_certificate",
                            )}
                        />
                    </Box>
                }
                rightSide={null}
            />

            {property.energy_certificate ===
                EnergyPassportOptions["Ja, Liegt Vor"] && (
                <>
                    <TwoColumnForm
                        leftSide={
                            <Box>
                                <LabeledSelectInput
                                    label={labels.energy_date_of_creation + "*"}
                                    enumObject={EnergyDateOfCreationOptions}
                                    name="energy_date_of_creation"
                                    handleSelectChange={handleSelectChange}
                                    value={property.energy_date_of_creation}
                                    disabled={id !== undefined}
                                    fullWidth
                                    hasError={validationErrors.includes(
                                        "energy_date_of_creation",
                                    )}
                                />

                                <LabeledNumberInput
                                    label={
                                        labels.energy_year_of_construction + "*"
                                    }
                                    name="energy_year_of_construction"
                                    placeholder="2000"
                                    handleChange={handleNumberChange}
                                    value={
                                        property.energy_year_of_construction?.toString() ||
                                        ""
                                    }
                                    fullWidth
                                    hasError={validationErrors.includes(
                                        "energy_year_of_construction",
                                    )}
                                />
                                <LabeledNumberInput
                                    label={labels.energy_need + "*"}
                                    name="energy_need"
                                    placeholder="250"
                                    handleChange={handleNumberChange}
                                    value={
                                        property.energy_need?.toString() || ""
                                    }
                                    fullWidth
                                    hasError={validationErrors.includes(
                                        "energy_need",
                                    )}
                                />
                                {
                                    <CheckboxInput
                                        name={"energy_for_hot_water_included"}
                                        label={
                                            labels.energy_for_hot_water_included
                                        }
                                        checked={
                                            property.energy_for_hot_water_included
                                        }
                                        handleChange={handleCheckboxChange}
                                    />
                                }
                            </Box>
                        }
                        rightSide={
                            <Box>
                                <LabeledSelectInput
                                    label={labels.energy_card_type + "*"}
                                    enumObject={EnergyCardTypeOptions}
                                    name="energy_card_type"
                                    handleSelectChange={handleSelectChange}
                                    value={property.energy_card_type}
                                    fullWidth
                                    hasError={validationErrors.includes(
                                        "energy_card_type",
                                    )}
                                />
                            </Box>
                        }
                    />

                    <TwoColumnForm
                        leftSide={
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography sx={{ fontWeight: "bold" }}>
                                    Wesentliche Energieträger
                                </Typography>

                                {ESSENTIAL_ENERGY_SOURCES.map((src) => {
                                    return (
                                        <FormControlLabel
                                            key={src}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        paddingBlock: 0,
                                                    }}
                                                    name={src}
                                                    checked={
                                                        property[
                                                            src as keyof Property
                                                        ] as boolean
                                                    }
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                />
                                            }
                                            label={labels[src]}
                                        />
                                    );
                                })}
                            </Box>
                        }
                        rightSide={
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography sx={{ fontWeight: "bold" }}>
                                    Heizungsart
                                </Typography>
                                {HEATING_OPTIONS.map((src) => {
                                    return (
                                        <FormControlLabel
                                            key={src}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        paddingBlock: 0,
                                                    }}
                                                    name={src}
                                                    checked={
                                                        property[
                                                            src as keyof Property
                                                        ] as boolean
                                                    }
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                />
                                            }
                                            label={labels[src]}
                                        />
                                    );
                                })}
                            </Box>
                        }
                    />
                </>
            )}
        </Box>
    ) : (
        <Box sx={propertyFormStyles.centeredContainer}>Unzutreffend</Box>
    );
};
